import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { userLogin as userLoginType } from "./index.td"
import produce from "immer"

const initialState = { userStatus: { loading: true, user: null, code: "", profile: null } }

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userRegistration: state => {
      state.userStatus.loading = true
    },
    userLogin: state => {
      state.userStatus.loading = true
    },
    userRegisterSuccess: (state, action: PayloadAction<userLoginType>) => {
      state.userStatus.loading = false
      state.userStatus.user = action.payload.user
      state.userStatus.code = ""
    },
    userRegisterFailed: (state, action: PayloadAction<userLoginType>) => {
      state.userStatus.loading = false
      state.userStatus.user = null
      state.userStatus.code = action.payload?.code
    },
    userLoginSuccess: (state, action: PayloadAction<userLoginType>) => {
      state.userStatus.loading = false
      state.userStatus.user = action.payload.user
      state.userStatus.code = ""
    },
    userLoginFailed: (state, action: PayloadAction<userLoginType>) => {
      state.userStatus.loading = false
      state.userStatus.user = null
      state.userStatus.code = action.payload?.code
    },
    userSignoutSuccess: state => {
      state.userStatus.loading = false
      state.userStatus.user = null
      state.userStatus.code = ""
    },
    userSignoutFailed: (state, action: PayloadAction<userLoginType>) => {
      state.userStatus.loading = false
      state.userStatus.user = null
      state.userStatus.code = action.payload?.code
    },
    passwordResetFailed: (state, action: PayloadAction<userLoginType>) => {
      state.userStatus.loading = false
      state.userStatus.user = null
      state.userStatus.code = action.payload?.code
    },
    updateUserProfileSuccess: (state, action: PayloadAction<userLoginType>) => {
      state.userStatus.loading = false
      state.userStatus.profile = action.payload.profile
      state.userStatus.code = ""
    },
    updateUserProfileFailed: (state, action: PayloadAction<userLoginType>) => {
      state.userStatus.loading = false
      state.userStatus.profile = action.payload.profile
      state.userStatus.code = ""
    }
  }
})

export const {
  userRegistration,
  userLogin,
  userRegisterSuccess,
  userLoginSuccess,
  userLoginFailed,
  userRegisterFailed
} = userSlice.actions
// export const selectCurrentUser = (state: any) => state.auth.user

export const userActions = userSlice.actions

export default userSlice
