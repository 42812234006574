import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { property as propertyType } from "./index.td"

const initialState = { propertyStatus: { loading: true, property: null, code: "" } }

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    propertyFetch: state => {
      state.propertyStatus.loading = true
    },
    hostPropertySuccess: (state, action: PayloadAction<propertyType>) => {
      ;(state.propertyStatus.loading = false),
        (state.propertyStatus.property = action.payload.property),
        (state.propertyStatus.code = "")
    },
    hostPropertyFailed: (state, action: PayloadAction<propertyType>) => {
      ;(state.propertyStatus.loading = false), (state.propertyStatus.code = action.payload.code)
    },
    updatePropertySuccess: (state, action: PayloadAction<propertyType>) => {
      ;(state.propertyStatus.loading = false),
        (state.propertyStatus.property = action.payload.property),
        (state.propertyStatus.code = "")
    },
    updatedPropertyFailed: (state, action: PayloadAction<propertyType>) => {
      ;(state.propertyStatus.loading = false),
        (state.propertyStatus.property = action.payload.property),
        (state.propertyStatus.code = action.payload.code)
    }
  }
})

export const { propertyFetch, hostPropertyFailed, hostPropertySuccess, updatePropertySuccess, updatedPropertyFailed } =
  propertySlice.actions

export const propertyActions = propertySlice.actions

export default propertySlice
