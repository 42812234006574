import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { kycProfile as kycProfileType } from "./index.td"

const initialState = { kycProfileStatus: { loading: true, profile: null, code: "" } }

const kycSlice = createSlice({
  name: "profile-kyc",
  initialState,
  reducers: {
    kycFetch: state => {
      state.kycProfileStatus.loading = true
    },
    kycSuccess: (state, action: PayloadAction<kycProfileType>) => {
      state.kycProfileStatus.loading = false
      state.kycProfileStatus.profile = action.payload.profile
      state.kycProfileStatus.code = ""
    },
    kycFailed: (state, action: PayloadAction<kycProfileType>) => {
      state.kycProfileStatus.loading = false
      state.kycProfileStatus.code = action.payload?.code
    }
  }
})

export const { kycFetch, kycSuccess, kycFailed } = kycSlice.actions
// export const selectCurrentUser = (state: any) => state.auth.user

export const userKycActions = kycSlice.actions

export default kycSlice
