import { configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import combineReducer from "./combined-reducers"
import { createLogger } from "redux-logger"

const sagaMiddleware = createSagaMiddleware()

export const middleware = [sagaMiddleware]

const __DEV__ = process.env.NODE_ENV == "development"
const __UAT__ = process.env.NODE_ENV == "staging"
const __PROD__ = process.env.NODE_ENV === "production"

if (__DEV__ && __UAT__ && !__PROD__) {
  middleware.push(createLogger() as any)
}

const Store = configureStore({
  reducer: combineReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false
    }).concat(...middleware)
  },
  devTools: __DEV__ && !__PROD__
})

export default Store
