import { combineReducers } from "@reduxjs/toolkit"
import userSlice from "../duck/auth"
import kycSlice from "../duck/profile"
import propertySlice from "../duck/property"

const auth = userSlice.reducer
const profileKyc = kycSlice.reducer
const property = propertySlice.reducer

const rootReducer = combineReducers({ auth, profileKyc, property })

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
